import Service from "../../models/service";

export default class ServiceSelect extends React.Component {
  onChange = event => {
    this.props.onChange(event.target.value);
  };

  componentDidMount() {
    const {selectedServiceCode, prefillService, services} = this.props;
    const isSelectedServiceCodePresent = services.some(service => service.label === selectedServiceCode);

    if (isSelectedServiceCodePresent || selectedServiceCode === null) {
      return selectedServiceCode;
    }
    else if (prefillService && services.length > 0) {
      this.props.onChange(services[0].label)
    }else{
      this.props.onChange('')
    }

  }

  render() {
    const { selectedServiceCode, readOnly, name, id, tabIndex, services } = this.props;
    return (
      <select
        readOnly={readOnly}
        value={selectedServiceCode}
        onChange={this.onChange}
        className="form-control input-sm clean select-service"
        name={name || "select-service"}
        id={id}
        tabIndex={tabIndex}
      >
        <option value="">{I18n.get().t("select_service")}</option>
        {services.map(service => (
          <option value={service.label} key={service.id}>
            {service.translation}
          </option>
        ))}
      </select>
    );
  }
}
