import FilterDays from "./filter_days.js";
import FilterHours from "./filter_hours.js";
import FilterSpecialities from "./filter_specialities.js";
import FilterLanguages from "./filter_languages.js";
import FilterCalendars from "./filter_calendars.js";
import MultipleServiceSelect from "./multiple_service_select.js";
import CenterSuggester from "./center_suggester.js";
import SuggestionGroupProvider from "./suggestion_group_provider.js";
import StepFilterGroupModal from "./step_filter_group_modal.js";
import ProgendaUtils from "shared/progenda_utils.js";

export default class PatientCenterSuggester extends React.Component {
  state = this.getInitialState();

  getInitialState() {
    let filters;
    if (window.location.search) {
      const languages = ProgendaUtils.getFromURL("languages");
      if (languages) {
        this.props.setLanguageFilter(languages.split(","));
      }
      filters = ProgendaUtils.getFromURL("filters");
      if (filters) {
        filters = filters.split(",");
      }
      var serviceGroups = ProgendaUtils.getFromURL("services");
      if (serviceGroups) {
        serviceGroups = serviceGroups.split(",");
        this.props.setServiceGroup(serviceGroups);
      }
    }
    return {
      displayedFilters: filters,
      displayServiceGroups: serviceGroups ? false : true,
      displayCalendarGroups: this.props.calendarsFromParams ? false : true
    };
  }

  componentDidMount() {
    this.servicesChanged();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectableServices !== prevProps.selectableServices ||
      this.props.selectedServiceCodes !== prevProps.selectedServiceCodes
    ) {
      this.servicesChanged();
    }
  }

  resetCalendarGroup = () => {
    this.props.setCalendarFilter([]);
  };

  resetServiceGroup = () => {
    this.props.setServiceGroup(null);
  };

  servicesChanged = () => {
    if (this.props.selectableServices !== null) {
      if (
        (this.props.selectableServices.length > 1 &&
          this.props.selectedServiceCodes.length > 0) ||
        this.props.selectableServices.length < 2
        // do not display modal if only zero or one service available
      ) {
        this._serviceModal && this._serviceModal.hide();
      } else {
        this._serviceModal && this._serviceModal.show();
      }
    }
  };

  resetFilters = () => {
    this.props.setSpecialityFilter([]);
    this.props.setCalendarFilter([]);
    this.props.setDayFilter([]);
    this.props.setHourFilter([]);
    this.props.setLanguageFilter([]);
  };

  render() {
    if (this.props.selectableServices === null) {
      return <div />;
    }
    return (
      <div className="row">
        <div className="col-sm-3 well search-center-well">
          <div className="search-bar-center-availabilities">
            <h1>{I18n.get().t("public_center_search_title")}</h1>
            {I18n.get().t("public_center_search_explanation")}
            <br />
            <br />

            {this.props.selectableServices.length > 0 && (
              <MultipleServiceSelect
                enableAppointmentsGroup={
                  this.props.center.enableAppointmentsGroup
                }
                services={this.props.uniqueSelectableServices}
                setSelectedServiceCodes={this.props.setSelectedServiceCodes}
                selectedServiceCodes={this.props.selectedServiceCodes}
              />
            )}

            {(!this.state.displayedFilters ||
              this.state.displayedFilters.includes("specialities")) && (
              <div>
                <FilterSpecialities
                  selectedCalendars={this.props.selectableCalendars}
                  setSpecialityFilter={this.props.setSpecialityFilter}
                  specialityFilter={this.props.specialityFilter}
                />
              </div>
            )}

            {(!this.state.displayedFilters ||
              this.state.displayedFilters.includes("calendars")) && (
              <div>
                <FilterCalendars
                  selectedCalendars={this.props.selectableCalendars}
                  center={this.props.center}
                  setCalendarFilter={this.props.setCalendarFilter}
                  calendarFilter={this.props.calendarFilter}
                />
              </div>
            )}

            {(!this.state.displayedFilters ||
              this.state.displayedFilters.includes("days")) && (
              <div>
                <FilterDays
                  setDayFilter={this.props.setDayFilter}
                  dayFilter={this.props.dayFilter}
                />
              </div>
            )}

            {(!this.state.displayedFilters ||
              this.state.displayedFilters.includes("hours")) && (
              <div>
                <FilterHours
                  calendars={this.props.selectableCalendars}
                  setHourFilter={this.props.setHourFilter}
                  hourFilter={this.props.hourFilter}
                />
              </div>
            )}

            {(!this.state.displayedFilters ||
              this.state.displayedFilters.includes("languages")) && (
              <div>
                <FilterLanguages
                  selectedCalendars={this.props.selectableCalendars}
                  setLanguageFilter={this.props.setLanguageFilter}
                  languageFilter={this.props.languageFilter}
                />
              </div>
            )}

            <div
              className="text-center reset-filters cursor-pointer"
              onClick={this.resetFilters}
            >
              {I18n.get().t("reset_filters")}
            </div>

            <StepFilterGroupModal
              ref={c => (this._serviceModal = c)}
              center={this.props.center}
              selectServiceGroup={this.props.setServiceGroup}
              resetServiceGroup={this.resetServiceGroup}
              displayServiceGroups={this.state.displayServiceGroups}
              displayCalendarGroups={this.state.displayCalendarGroups}
              selectCalendarGroup={this.props.setCalendarFilter}
              resetCalendarGroup={this.resetCalendarGroup}
              services={this.props.availableUniqueSelectableServices}
              serviceSelectText={this.props.center.serviceSelectText}
              selectedServiceCodes={this.props.selectedServiceCodes}
              setSelectedServiceCodes={this.props.setSelectedServiceCodes}
            />
          </div>
        </div>
        <div className="col-sm-9">
          <h1>{I18n.get().t("search_results")}</h1>
          <SuggestionGroupProvider {...this.props}>
            <CenterSuggester center={this.props.center} />
          </SuggestionGroupProvider>
        </div>
      </div>
    );
  }
}
