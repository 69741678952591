import XSSFilter from "shared/components/xss_filter.tsx";
import CalendarUtils from "shared/calendar_utils.js";

const FilterGroupSelection = ({
  filterGroups,
  selectFilterGroup,
  filterGroupName
}) => (
  <div className="calendar-group-container">
    {CalendarUtils.sortfilters(filterGroups).map(filterGroup => (
      <div className="calendar-group-card">
        <div
          className="calendar-group"
          onClick={() => {
            selectFilterGroup(filterGroup[filterGroupName]);
          }}
        >
          <img src={filterGroup.picture} />
          <div className="calendar-group-info">
            <h2 className="calendar-group-name">{filterGroup.name}</h2> <br />
            <span className="calendar-group-description">
              <XSSFilter content={filterGroup.description} />
            </span>{" "}
            <br />
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default FilterGroupSelection;
