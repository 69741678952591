import ProgendaUtils from "shared/progenda_utils.js";
export default class CalendarSelectionProvider extends React.Component {
  constructor(props) {
    super(props);
    let calendars = this.props.calendars;
    let calendarsFromParams = ProgendaUtils.getFromURL("calendars");
    if(calendarsFromParams) {
      calendarsFromParams = calendarsFromParams.split(",").map(str => parseInt(str))
    }
    this.state = {
      selectableCalendars: calendarsFromParams ? calendars.filter(c => calendarsFromParams.includes(c.id)) : calendars,
      calendarsFromParams: calendarsFromParams,
      filteredCalendars: [],
      calendarFilter: calendarsFromParams ? calendarsFromParams : [],
      specialityFilter: [],
      languageFilter: []
    };
  }
  componentDidMount() {
    this.filterCalendars();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.calendars &&
      (this.props.calendars !== prevProps.calendars ||
        this.state.calendarFilter !== prevState.calendarFilter ||
        this.state.specialityFilter !== prevState.specialityFilter ||
        this.state.languageFilter !== prevState.languageFilter)
    ) {
      this.filterCalendars();
    }
  }
  filterCalendars() {
    const filteredCalendars = this.props.calendars
      .filter(
        c =>
          this.state.calendarFilter.length === 0 ||
          this.state.calendarFilter.includes(c.id)
      )
      .filter(
        c =>
          this.state.languageFilter.length === 0 ||
          c.languages.some(l => this.state.languageFilter.includes(l.code))
      )
      .filter(
          c =>
              this.state.specialityFilter.length === 0 ||
              c.specialities.some(l => this.state.specialityFilter.includes(l.id))
      )
    this.setState({ filteredCalendars: filteredCalendars });
  }
  setCalendarFilter = calendarFilter => {
    this.setState({ calendarFilter: calendarFilter });
  };

  setSpecialityFilter = specialityFilter => {
    this.setState({ specialityFilter: specialityFilter });
  };

  setLanguageFilter = languageFilter => {
    this.setState({ languageFilter: languageFilter });
  };

  render() {
    const { children, calendars } = this.props;

    const childWithProp = React.Children.map(children, child => {
      return React.cloneElement(child, {
        calendarsFromParams: this.state.calendarsFromParams,
        selectableCalendars: this.state.selectableCalendars,
        selectedCalendars: this.state.filteredCalendars,
        setCalendarFilter: this.setCalendarFilter,
        setSpecialityFilter: this.setSpecialityFilter,
        setLanguageFilter: this.setLanguageFilter,
        calendarFilter: this.state.calendarFilter,
        specialityFilter: this.state.specialityFilter,
        languageFilter: this.state.languageFilter
      });
    });

    return childWithProp;
  }
}
