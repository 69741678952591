import FilterGroupSelection from "./filter_group_selection.js";
import Step from "shared/components/step.js";

export default class CalendarFilterGroupModal extends React.Component {
  selectCalendarGroup(calendars) {
    this._modal.hide();
    this.props.selectCalendarGroup(calendars);
  }

  render() {
    return (
      <Step ref={c => (this._modal = c)} onHide={this.props.onHide}>
        <div className="well select-service-center-search">
          <div className="text-center">
            <h3>
              {I18n.get().t("please_select_calendar_group", {
                filter_designation: I18n.get().t(this.props.center.filterDesignation)
              })}
            </h3>
            <br />
            <br />
            <FilterGroupSelection
              filterGroups={this.props.center.calendarFilters}
              filterGroupName="calendars"
              selectFilterGroup={this.selectCalendarGroup.bind(this)}
            />
            <br />
          </div>
        </div>
      </Step>
    );
  }
}
